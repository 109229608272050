import React from 'react';

import HeroMap from './HeroMap';

const mapboxgl = window.mapboxgl;
mapboxgl.accessToken = 'pk.eyJ1IjoibWFwc3RlcnRlY2giLCJhIjoiY2poaHk1aDg0MDI0NzMwbnl5OGl0eGg3ZCJ9.fPIgJrmVEyN8Hdvk2EDvXA';

class SampleMap extends React.Component {

  state = {
    demoModalActive: false,
    testMap : false,
    over1check : true,
    over100check : true,
  }

  componentDidMount = () => {
    const map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [-123.11944126651028, 49.26716762485108], // starting position [lng, lat]
      zoom: 11.5 // starting zoom
    });

    // console.log(MapsterAnalytics)
    window.MapsterAnalytics(map, 'b4V29TJm6o', true, this.props.bigWorkingAnalyticsObject);

    this.setState({testMap : map})


    map.on('load', () => {

      const popup = new mapboxgl.Popup({ closeOnClick: false });

      const formatPrice = (price) => {
        if(price > 1000000) {
          return '$' + (price / 1000000).toFixed(0) + 'M';
        }
        if(price > 1000) {
          return '$' + (price / 1000).toFixed(0) + 'K';
        }
      }
      fetch('/data/test-geojson.geojson').then(resp => resp.json()).then(response => {

        var icons = 'alcohol-shop american-football amusement-park aquarium art-gallery attraction bakery bank bar basketball beach beer bicycle bowling-alley bridge cafe campsite car car-rental car-repair casino castle cemetery charging-station cinema clothing-store college communications-tower confectionery convenience dentist doctor dog-park drinking-water embassy farm fast-food fire-station fitness-centre fuel furniture garden globe golf grocery harbor hardware horse-riding hospital ice-cream information jewelry-store laundry library lodging marker mobile-phone monument museum music optician park parking parking-garage pharmacy picnic-site pitch place-of-worship playground police post prison ranger-station religious-buddhist religious-christian religious-jewish religious-muslim restaurant restaurant-noodle restaurant-pizza restaurant-seafood school shoe shop skateboard slipway stadium suitcase swimming table-tennis tennis theatre toilet town-hall veterinary viewpoint volleyball watch watermill windmill zoo';
        var iconList = icons.split(' ');

        response.features.forEach(feature => {
          if(feature.geometry.type === 'Point') {
            feature.id = parseInt(1000000 * Math.random())
            var price = parseInt(10000000 * Math.random() * Math.random())
            feature.properties = {
              name : 'Property For Sale',
              price : price,
              priceLabel : formatPrice(price),
              icon : iconList[Math.floor(Math.random() * iconList.length)] + '-11',
              image : 'https://via.placeholder.com/150x50'
            }
          }
        })

        map.addSource('sample-data', {
          type : 'geojson',
          data : response
        })
        map.addLayer({
          id : 'sample-data-fill',
          type : 'fill',
          source : 'sample-data',
          filter: ['==', '$type', 'Polygon'],
          paint : {
            'fill-opacity' : 0.3,
            'fill-color' : '#FFF',
          }
        })
        map.addLayer({
          id : 'sample-data-line',
          type : 'line',
          source : 'sample-data',
          paint : {
            'line-width' : 2,
            'line-color' : '#333',
            'line-dasharray': [2, 1],
          }
        })
        map.addLayer({
          id : 'sample-data-circle',
          type : 'circle',
          source : 'sample-data',
          filter: ['==', '$type', 'Point'],
          paint : {
            'circle-opacity' : 1,
            'circle-radius' : 12,
            'circle-color' : [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#ccc',
              '#FFF'
            ]
          },
          layout : {
          }
        })

        let hoveredStateId = null;
        map.on('mousemove', 'sample-data-circle', (e) => {
          if (e.features.length > 0) {
            if (hoveredStateId !== null) {
              map.setFeatureState(
                { source: 'sample-data', id: hoveredStateId },
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
              { source: 'sample-data', id: hoveredStateId },
              { hover: true }
            );
          }
        })
        map.on('mouseleave', 'sample-data-circle', () => {
          if (hoveredStateId !== null) {
            map.setFeatureState(
              { source: 'sample-data', id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = null;
        });
        map.addLayer({
          id : 'sample-data-symbol',
          type : 'symbol',
          source : 'sample-data',
          filter: ['==', '$type', 'Point'],
          layout : {
            'text-field' : ['get', 'priceLabel'],
            'text-offset' : [0, 1.5],
            'text-size' : 13,
            'icon-image' : ['get', 'icon'],
            'icon-size' : 1.2,
            'icon-allow-overlap' : true,
            'text-optional' : true,
            // 'text-ignore-placement' : true,
            'icon-ignore-placement' : true
          },
          paint : {
            'text-color' : '#333',
            'text-halo-color' : '#FFF',
            'text-halo-width' : 1,
            'text-halo-blur' : 0.3,
            'icon-color' : '#333'
          }
        })
        map.on('click', 'sample-data-circle', (e) => {
          var features = map.queryRenderedFeatures(e.point)
          var propFeature = features[0];
          popup
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML(`
              <div>
                <img src="${propFeature.properties.image}" style="margin-bottom: 10px"/>
                <strong>${propFeature.properties.name}</strong><br />
                ${propFeature.properties.priceLabel}
              </div>
            `)
            .addTo(map);
        })
      })
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { over1check, over100check, testMap } = this.state;
    if(testMap && (prevState.over1check !== over1check || prevState.over100check !== over100check)) {
      var filter = ['all', ['==', '$type', 'Point']];
      if(over1check) {
        if(!over100check) {
          filter.push(['>=', 'price', 1000000])
        }
      } else {
        filter.push(['<=', 'price', 1000000])
      }
      if(over100check) {
        filter.push(['>=', 'price', 100000])
      } else {
        filter.push(['<=', 'price', 100000])
      }
      testMap.setFilter('sample-data-circle', filter)
      testMap.setFilter('sample-data-symbol', filter)
    }
  }

  render() {

    const { over1check, over100check } = this.state;

    return (
      <React.Fragment>
        <HeroMap />
        <div className="container-xs">
          <div className="map-options">
            <div>
              <input type="checkbox" checked={over1check} onChange={() => this.setState({over1check : !over1check})} /> Buildings Over $1M
            </div>
            <div>
              <input type="checkbox" checked={over100check} onChange={() => this.setState({over100check : !over100check})} /> Buildings Over $100K
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SampleMap;
