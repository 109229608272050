import React from 'react';
// import section header
import SectionHeader from './partials/SectionHeader';
// import sections
import GenericSection from './GenericSection';
// import some required elements
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Accordion from '../elements/Accordion';
import AccordionItem from '../elements/AccordionItem';

import * as turf from '@turf/turf';
import Slider, { SliderTooltip } from 'rc-slider';
import rdiff from 'recursive-diff';
import polyline from '@mapbox/polyline';

import 'rc-slider/assets/index.css';

const { Handle } = Slider;

const mapboxgl = window.mapboxgl;
mapboxgl.accessToken = 'pk.eyJ1IjoibWFwc3RlcnRlY2giLCJhIjoiY2poaHk1aDg0MDI0NzMwbnl5OGl0eGg3ZCJ9.fPIgJrmVEyN8Hdvk2EDvXA';

class AnalyticsSection extends React.Component {

  state = {
    demoModalActive: false,
    analyticsData : false,
    mapTimeline : false,
    analyticsMap : false,
    sliderValue : 0,
  }

  componentDidMount = () => {

    const analyticsMap = new mapboxgl.Map({
      container: 'analytics-map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11'
    });
    this.setState({ analyticsMap })
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  decodeQueryString = (string) => {
    const urlParams = new URLSearchParams(string);
    const params = Object.fromEntries(urlParams.entries());
    return params;
  }

  loadAnalytics = () => {
    const { analyticsMap } = this.state;

    var mapTimeline = [];

    fetch('https://mapster-analytics-api.herokuapp.com/api/sample/map', {
    // fetch('http://localhost:4000/api/sample/map', {
      method : "POST",
      headers: {
          "Content-type": "application/json",
      },
      body : JSON.stringify({
        clicks : this.props.bigWorkingAnalyticsObject.click ? this.props.bigWorkingAnalyticsObject.click.map(qs => this.decodeQueryString(qs)) : [],
        layers : this.props.bigWorkingAnalyticsObject.layer ? this.props.bigWorkingAnalyticsObject.layer.map(qs => this.decodeQueryString(qs)) : [],
        mousemoves : this.props.bigWorkingAnalyticsObject.mousemove ? this.props.bigWorkingAnalyticsObject.mousemove.map(qs => this.decodeQueryString(qs)) : [],
        moves : this.props.bigWorkingAnalyticsObject.mapmove ? this.props.bigWorkingAnalyticsObject.mapmove.map(qs => this.decodeQueryString(qs)) : [],
        load : this.props.bigWorkingAnalyticsObject.loadsuccess ? this.props.bigWorkingAnalyticsObject.loadsuccess.map(qs => this.decodeQueryString(qs)) : false,
        popups : this.props.bigWorkingAnalyticsObject.popup ? this.props.bigWorkingAnalyticsObject.popup.map(qs => this.decodeQueryString(qs)) : [],
        renders : this.props.bigWorkingAnalyticsObject.render ? this.props.bigWorkingAnalyticsObject.render.map(qs => this.decodeQueryString(qs)) : [],
        errors : this.props.bigWorkingAnalyticsObject.error ? this.props.bigWorkingAnalyticsObject.error.map(qs => this.decodeQueryString(qs)) : []
      })
    }).then(resp => resp.json()).then(response => {
      console.log(response);

      var timelineEvents = response.analytics.timeline;

      analyticsMap.fitBounds(JSON.parse(response.analytics.data.loadData.bounds));

      // First, I'll want to draw all the core starting information (bounds, center)
      var boundsFeatureCollection = { type : "FeatureCollection", features : [] }
      timelineEvents.forEach(anEvent => {
        if(anEvent.bounds) {
          var parsedBounds = JSON.parse(anEvent.bounds);
          var bboxPolygon = turf.bboxPolygon([parsedBounds[0][0], parsedBounds[0][1], parsedBounds[1][0], parsedBounds[1][1]]);
          bboxPolygon.properties.time = anEvent.time;
          boundsFeatureCollection.features.push(bboxPolygon);
        }
      })

      analyticsMap.addSource('bounds', {
        type : 'geojson',
        data : boundsFeatureCollection
      })
      analyticsMap.addLayer({
        id: 'bounds',
        type : 'line',
        source : 'bounds',
        paint : {
          'line-opacity' : [
            'interpolate', ['linear'],
            ['number', ['get', 'time']],
            timelineEvents[0].time, 1,
            timelineEvents[timelineEvents.length-1].time, 0.1
          ],
          'line-color' : '#000'
        }
      })

      var clicksFeatureCollection = { type : "FeatureCollection", features : [] }
      timelineEvents.forEach(anEvent => {
        if(anEvent.location) {
          var clickPoint = { type : "Feature", properties : { time : anEvent.time },
            geometry : {
              type : "Point",
              coordinates : anEvent.location
            }
          }
          clicksFeatureCollection.features.push(clickPoint)
        }
      })

      analyticsMap.addSource('clicks', {
        type : 'geojson',
        data : clicksFeatureCollection
      })
      analyticsMap.addLayer({
        id: 'clicks',
        type : 'circle',
        source : 'clicks',
        paint : {
          'circle-radius' : 0,
          'circle-color' : '#FF0000',
          'circle-stroke-color' : '#CD5C5C',
          'circle-stroke-opacity' : 0.8,
          'circle-stroke-width' : 0
        }
      })

      var mousemoveFeatureCollection = { type : "FeatureCollection", features : [] }
      var mousemoveFeatureCollectionPoints = { type : "FeatureCollection", features : [] }
      timelineEvents.forEach(anEvent => {
        if(anEvent.line) {
          var mousemoveLine = { type : "Feature", properties : { time : anEvent.lineTimeline, startTime : anEvent.time },
            geometry : {
              type : "LineString",
              coordinates : anEvent.line
            }
          }
          mousemoveFeatureCollection.features.push(mousemoveLine)
          anEvent.line.forEach((point, i) => {
            var mousemovePoint = { type : "Feature", properties : { time : anEvent.lineTimeline[i] },
              geometry : {
                type : "Point",
                coordinates : point
              }
            }
            mousemoveFeatureCollectionPoints.features.push(mousemovePoint)
          })
        }
      })
      analyticsMap.addSource('mousemoves', {
        type : 'geojson',
        data : mousemoveFeatureCollection
      })
      analyticsMap.addLayer({
        id: 'mousemoves',
        type : 'line',
        source : 'mousemoves',
        paint : {
          'line-width' : 0
        }
      })
      analyticsMap.addSource('mousemovesPoint', {
        type : 'geojson',
        data : mousemoveFeatureCollectionPoints
      })
      analyticsMap.addLayer({
        id: 'mousemovesPoint',
        type : 'circle',
        source : 'mousemovesPoint',
        paint : {
          'circle-radius' : 0
        }
      })

      this.setState({mapTimeline : timelineEvents, sliderValue : timelineEvents[0].time, analyticsData : response.analytics })
    })
    // Display all the mouseing, dragging information on the map itself
    // Then have a button to "re-create journey" for the user
  }

  timelineChange = (val) => {
    const { analyticsMap, mapTimeline } = this.state;
    mapTimeline.forEach(mapEvent => {
      var firstTime = mapTimeline[0].time;
      var lastTime = mapTimeline[mapTimeline.length-1].time;
      var thisTime = mapEvent.time;
      if(val === thisTime) {
        // BOUNDS CHANGE
        if(mapEvent.bounds) {
          analyticsMap.fitBounds(JSON.parse(mapEvent.bounds))
          var mapboxExpression = [
            'interpolate', ['linear'],
            ['number', ['get', 'time']]
          ]
          if(firstTime !== thisTime) {
            mapboxExpression.push(parseInt(firstTime))
            mapboxExpression.push(0)
          }
          mapboxExpression.push(parseInt(thisTime))
          mapboxExpression.push(1)
          if(lastTime !== thisTime) {
            mapboxExpression.push(parseInt(lastTime))
            mapboxExpression.push(0)
          }
          analyticsMap.setPaintProperty('bounds', 'line-opacity', mapboxExpression)
        }

        // CLICK CHANGE
        if(mapEvent.location) {
          var mapboxExpression = [
              'case',
              ['<=', ['get', 'time'], thisTime], 5,
              0
          ]
          var mapboxExpressionCopy = JSON.parse(JSON.stringify(mapboxExpression))
          mapboxExpressionCopy[mapboxExpressionCopy.length-2] = 4;
          analyticsMap.setPaintProperty('clicks', 'circle-radius', mapboxExpression)
          analyticsMap.setPaintProperty('clicks', 'circle-stroke-width', mapboxExpressionCopy)
        }

        // MOUSEMOVE CHANGE
        const timeoutFunction = (time, timeElapsed) => {
          setTimeout(() => {
            var mapboxExpressionPoint = [
                'case',
                ['<=', ['get', 'time'], time], 3,
                0
            ]
            analyticsMap.setPaintProperty('mousemovesPoint', 'circle-radius', mapboxExpressionPoint)
          }, timeElapsed)
        }
        if(mapEvent.line) {
          mapEvent.lineTimeline.forEach((time, i) => {
            var timeElapsed = time - mapEvent.lineTimeline[0]
            timeoutFunction(time, timeElapsed)
          })
          var mapboxExpression = [
              'case',
              ['<=', ['get', 'startTime'], thisTime], 2,
              0
          ]
          analyticsMap.setPaintProperty('mousemoves', 'line-width', mapboxExpression)
        }

        this.setState({ sliderValue : thisTime })
      }
    })
  }

  playTimeline = () => {
    const { mapTimeline } = this.state;
    var startTime = parseInt(mapTimeline[0].time)
    mapTimeline.forEach(mapEvent => {
      var thisTime = mapEvent.time ? parseInt(mapEvent.time) : parseInt(mapEvent.timeStart);
      setTimeout(() => {
        this.timelineChange(thisTime)
        this.setState({ sliderValue : thisTime })
      }, thisTime-startTime)
    })
  }

  render() {

    const { analyticsData, mapTimeline, sliderValue, over1check, over100check } = this.state;

    var marks = {}
    if(mapTimeline) {
      mapTimeline.forEach(mapEvent => {
        var thisTime = mapEvent.time
        marks[thisTime] = ''
      })
    }

    const handle = props => {
      const { value, dragging, index, ...restProps } = props;
      var thisElement = mapTimeline.find(anEvent => anEvent.time === value)
      var thisTimeLabel = ((thisElement.time - parseInt(mapTimeline[0].time))/1000).toFixed(2) + 's'
      return (
        <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlay={`${thisTimeLabel} ${thisElement.description}`}
          visible={true}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </SliderTooltip>
      );
    };

    return (
      <React.Fragment>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Now, press the button below to load map analytics for what you just did.</h2>
            <p>
              You'll see fully anonymized information about your user: the device they are using, the size of the map they are seeing, and more.
            </p>
            <p>
              You'll be able to view their journey around your map, including mouse movements, layer changes, clicks, zooms, and drags.
            </p>
            <p>
              In the real world, this data will also be available in an aggregated format, so you can see where users spend the most time, what they click the most, how many errors they run into, load times, and more.
            </p>
          </div>
          <div className="container-sm analytics-result">
            <div style={{marginTop:'20px',marginBottom:'20px'}}>
              {!mapTimeline ?
                <div id="loader-map" style={{zIndex: 2000, textAlign: 'center', maxWidth: '896px', width:"100%", paddingTop: '15%', height:'400px', position: 'absolute', background: 'rgba(255,255,255,0.7)'}}>
                  <Button style={{margin: '0 auto'}} color="primary" onClick={() => this.loadAnalytics()}>Load my analytics</Button>
                </div>
              : false}
              <div id="analytics-map" style={{width:"100%", height:'400px'}}/>
              <div style={{marginBottom: '50px'}}>
                {mapTimeline ?
                  <div>
                    <h5></h5>
                    <div onClick={() => this.playTimeline()} style={{textAlign: 'center', width: '200px', cursor: 'pointer', margin : '0 auto', marginBottom: '20px'}}>
                      Replay your session
                      <div style={{width: '50px', margin:'0 auto'}}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" style={{borderRadius: '50%', boxShadow: '2px 2px 10px #ccc'}}>
                          <g>
                            <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"/>
                            <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"/>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <Slider handle={handle} value={sliderValue} min={mapTimeline[0].time} max={mapTimeline[mapTimeline.length-1].time} marks={marks} step={null} onChange={(e) => this.timelineChange(e)} />
                  </div>
                 : false}
              </div>
              {analyticsData ?
                <Accordion>
                  <AccordionItem title="User Information">
                    <p>All the basic details about your user.</p>
                    <table>
                      <tbody>
                        <tr><td>Platform</td><td>{analyticsData.stats.loadStats.platform}</td></tr>
                        <tr><td>Browser</td><td>{analyticsData.stats.loadStats.browser}</td></tr>
                        <tr><td>OS</td><td>{analyticsData.stats.loadStats.os}</td></tr>
                        <tr><td>Location</td><td>{analyticsData.data.loadData.location.city}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                  <AccordionItem title="Map Init Information">
                    <p>About the load of the map.</p>
                    <table>
                      <tbody>
                        <tr><td>Map Height</td><td>{analyticsData.stats.loadStats.height}px</td></tr>
                        <tr><td>Map Width</td><td>{analyticsData.stats.loadStats.width}px</td></tr>
                        <tr><td>Style</td><td>{analyticsData.stats.loadStats.style}</td></tr>
                        <tr><td>First Render Time</td><td>{parseFloat(analyticsData.stats.loadStats.timeEnd) - parseFloat(analyticsData.stats.loadStats.timeStart)}ms</td></tr>
                        <tr><td>Time</td><td>{new Date(parseFloat(analyticsData.stats.loadStats.timeStart)).toLocaleTimeString()}</td></tr>
                        <tr><td>Center</td><td>{analyticsData.stats.loadStats.center}</td></tr>
                        <tr><td>Zoom</td><td>{analyticsData.stats.loadStats.zoom}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                  <AccordionItem title="Layer Information">
                    <p>All information related to modification of layers on the map. <strong>This allows you to track filter, mouseover, and styling changes</strong>. This includes the addition of new layers, the changing of any properties of any layers, and the removal of layers. Each layer is recorded individually and can be aggregated as desired.</p>
                    <table>
                      <tbody>
                        <tr><td>Changes</td><td>{analyticsData.stats.layerStats.changes}</td></tr>
                        <tr><td>Additions</td><td>{analyticsData.stats.layerStats.additions}</td></tr>
                        <tr><td>Updates</td><td>{analyticsData.stats.layerStats.updates}</td></tr>
                        <tr><td>Removals</td><td>{analyticsData.stats.layerStats.removals}</td></tr>
                        <tr><td>Top Layer</td><td>{analyticsData.stats.layerStats.topLayers[0].layer}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                  <AccordionItem title="Activity Information">
                    <p>Includes zooms, pans, tilts, rotates, clicks, and mousemoves. This information is aggregated to create heatmaps and data charts.</p>
                    <table>
                      <tbody>
                        <tr><td>Map movements</td><td>{analyticsData.stats.moveStats.count}</td></tr>
                        <tr><td>Mouse movements</td><td>{analyticsData.stats.mousemoveStats.count}</td></tr>
                        <tr><td>Mousetime on map</td><td>{analyticsData.stats.mousemoveStats.mousetime}ms</td></tr>
                        <tr><td>Clicks</td><td>{analyticsData.stats.clickStats.count}</td></tr>
                        <tr><td>Pans</td><td>{analyticsData.stats.moveStats.pans}</td></tr>
                        <tr><td>Zooms</td><td>{analyticsData.stats.moveStats.zooms}</td></tr>
                        <tr><td>Tilts</td><td>{analyticsData.stats.moveStats.tilts}</td></tr>
                        <tr><td>Rotations</td><td>{analyticsData.stats.moveStats.rotations}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                  <AccordionItem title="Popup Information">
                    <p>Tells you when a popup is opened or closed, as well as aggregate data about popup usage.</p>
                    <table>
                      <tbody>
                        <tr><td>Popups opened</td><td>{analyticsData.stats.popupStats.opened}</td></tr>
                        <tr><td>Popups closed</td><td>{analyticsData.stats.popupStats.closed}</td></tr>
                        <tr><td>Most Viewed Popup</td><td>{analyticsData.stats.popupStats.mostViewed}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                  <AccordionItem title="Render Information">
                    <div>
                      <p>Allows you to see which tiles get loaded and how quickly the loading occurs.</p>
                      <table>
                        <tbody>
                          <tr><td>Renders</td><td>{analyticsData.stats.renderStats.count}</td></tr>
                          <tr><td>Avg Render Time</td><td>{analyticsData.stats.renderStats.avgTime}ms</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </AccordionItem>
                  <AccordionItem title="Error Information">
                    <p>Any information concerning errors with your map.</p>
                    <table>
                      <tbody>
                        <tr><td>Errors</td><td>{analyticsData.stats.errorStats.count}</td></tr>
                      </tbody>
                    </table>
                  </AccordionItem>
                </Accordion>
              : false}
            </div>
          </div>
          <div className="container-xs" style={{marginTop: '20px'}}>
            <p>
              And yes -- this is available for Swift, Kotlin, React Native, and plain ol' web projects. We cover it all. See our full details page for more information about each OS and map platform we cover.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../../assets/mapster/main-analytics.PNG')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">A view of a single map's aggregated analytics.</figcaption>
            </figure>
            <h4>Dashboard displays include:</h4>
            <ul>
              <li>Live map users, sorted by unique and returning visits.</li>
              <li>Most viewed popups, layers.</li>
              <li>Heatmaps of clicked and viewed areas.</li>
              <li>Information about map movements, pans, zooms, mouse movements, and more.</li>
              <li>Abilities to replay individual user sessions to gain detailed insights.</li>
            </ul>
            <h4>Anonymized data collected includes:</h4>
            <ul>
              <li><strong>User:</strong> Location, browser, device, mobile or desktop, platform.</li>
              <li><strong>Map:</strong> Map type, height and width, load time, load location, render times, errors.</li>
              <li><strong>Interaction:</strong> Clicks, moves, pans, zooms, popups, layer changes, and more.</li>
            </ul>
            <p>
              We want to make your maps succeed through providing great data. We are happy to add more functionality to integrate with your existing systems!
            </p>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default AnalyticsSection;
