import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
import SampleMap from '../components/sections/SampleMap';
import AnalyticsSection from '../components/sections/AnalyticsSection';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

import * as Bowser from 'bowser';
import * as turf from '@turf/turf';
import Slider, { SliderTooltip } from 'rc-slider';
import rdiff from 'recursive-diff';
import polyline from '@mapbox/polyline';

import 'rc-slider/assets/index.css';

const { Handle } = Slider;

var bigWorkingAnalyticsObject = {};

class Secondary extends React.Component {

  state = {
    demoModalActive: false,
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <SampleMap bigWorkingAnalyticsObject={bigWorkingAnalyticsObject} />

        <AnalyticsSection bigWorkingAnalyticsObject={bigWorkingAnalyticsObject} />

        <Cta topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Secondary;
